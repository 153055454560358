import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"pa-0",attrs:{"justify":"center"}},[_c(VSnackbar,{attrs:{"multi-line":"","vertical":"","top":"","right":"","color":_vm.alert.color},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.alert.active = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.alert.active),callback:function ($$v) {_vm.$set(_vm.alert, "active", $$v)},expression:"alert.active"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.alert.text)+" ")])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('label',[_vm._v("Correo eléctronico")]),_c(VTextField,{attrs:{"rules":_vm.emailRules,"append-icon":"email","placeholder":"email@example.com","required":"","outlined":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1),_c(VCardActions,{staticClass:"py-0 pa-0"},[_c(VBtn,{attrs:{"text":"","dark":"","color":"error"},on:{"click":function($event){return _vm.$emit('modal',false)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" keyboard_backspace ")]),_vm._v(" Regresar ")],1),_c(VSpacer),_c(VBtn,{attrs:{"disabled":!_vm.valid,"dark":"","color":"success"},on:{"click":function($event){return _vm.ValidarCorreo()}}},[_vm._v("Validar Correo")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }