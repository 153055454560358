import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"py-0"},[_c(VBtn,{staticClass:"ir-arriba",staticStyle:{"display":"none"},attrs:{"color":"accent","dark":"","fab":"","fixed":"","bottom":"","right":""}},[_c(VIcon,{attrs:{"top":""}},[_vm._v("keyboard_arrow_up")])],1),_c(VCol,{staticClass:"pa-0 py-0",attrs:{"cols":"12"}},[_c('BANNER')],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('PRODUCTOS',{staticClass:"pa-3"})],1),_c(VCol,{staticClass:"pa-0 mt-12",attrs:{"cols":"12"}},[(this.$vuetify.breakpoint.name === 'xs')?_c('CELANUNCIO'):_c('ANUNCIO')],1),_c(VCol,{attrs:{"cols":"12"}},[_c('PRODMASVEND',{staticClass:"py-0"})],1),_c(VCol,{staticClass:"mt-1 text-center",attrs:{"cols":"12"}},[_c('FOOTER')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }