import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"py-0 pt-10",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"elevation-0 transparent",attrs:{"size":"44","rounded":""}},[_c(VImg,{attrs:{"src":require("@/assets/iconos/categorias.png"),"width":"42","height":"42"}})],1),_c('div',{staticClass:"ms-3 mt-4"},[_c('p',{staticClass:"text-xs mb-0"}),_c('h2',{staticClass:"font-weight-bold"},[_vm._v("Categorías")]),_c('p')])],1)]),_vm._l((_vm.categorias),function(catego,i){return _c(VCol,{key:i,attrs:{"cols":"12","md":"3","lg":"3","xl":"2"}},[_c(VCard,{staticClass:"elevation-0 transparent text-center",staticStyle:{"border-radius":"1000px !important"},attrs:{"align":"center"},on:{"click":function($event){return _vm.buscar_por_categoria(catego)}}},[_c(VAvatar,{staticClass:"white elevation-6",attrs:{"size":"150"}},[(catego.foto)?_c('img',{attrs:{"src":_vm.url + catego.foto}}):_c('img',{attrs:{"src":_vm.url + 'nophoto.png'}})]),_c(VCardActions,{staticClass:"text-center pt-0 pb-0"},[_c(VSpacer),_c('p',{staticClass:"text-xs mb-0 text-center",staticStyle:{"height":"60px"}}),_c('h5',{staticClass:"font-weight-bold text-center"},[_vm._v(_vm._s(catego.categoria)+" ")]),_c('p'),_c(VSpacer)],1)],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }