import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Fetichismo from '@/views/Fetichismo/fetichismo.vue'

import Tendencias from '@/views/Anuncios/tendencias.vue'
import MiJuguete from '@/views/Anuncios/mi_juguete.vue'
import RegaloPerfecto from '@/views/Anuncios/regalo_perfecto.vue'

import ProcesarPedido from '@/views/Pedido/procesar.pedido.vue'
import ProcesarPedidoSuccess from '@/views/Pedido/procesar.pedido.success.vue'


import prueba_componente from '@/views/prueba_componente.vue'
import CatArts           from '@/views/Productos/CatArts.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/fetichismo', name: 'fetichismo', component: Fetichismo },


    { path: '/detalle.del.producto/:id', name: 'detalle.del.producto', component: () => { return import ('../views/Detalle_de_producto.vue') } },
    { path: '/detalle.cuenta', name: 'detalle.cuenta', component: () => { return import ('@/views/Cliente/cuenta.vue') } },
    { path: '/pedidos', name: 'pedidos', component: () => { return import ('@/views/Cliente/pedidos.vue') } },
    { path: '/direcciones', name: 'direcciones', component: () => { return import ('@/views/Cliente/direcciones.vue') } },
    { path: '/direcciones.facturacion', name: 'direcciones.facturacion', component: () => { return import ('@/views/Cliente/facturaciones.vue') } },
    { path: '/deseos', name: 'deseos', component: () => { return import ('@/views/Cliente/deseos.vue') } },
    { path: '/listado.productos/:id', name: 'listado.productos', component: () => { return import ('@/views/Productos/listado_productos.vue') } },
    // { path: '/fetichismo'              , name: 'fetichismo'                 , component: () => { return import('@/views/Fetichismo/fetichismo.vue') } },

    // ANUNCIOS
    { path: '/tendencias', name: 'tendencias', component: Tendencias },
    { path: '/mi.primer.juguete', name: 'mi.primer.juguete', component: MiJuguete },
    { path: '/el.regalo.perfecto', name: 'el.regalo.perfecto', component: RegaloPerfecto },
    { path: '/procesar.pedido', name: 'procesar.pedido', component: ProcesarPedido },
    { path: '/procesar.pedido.success', name: 'procesar.pedido.success', component: ProcesarPedidoSuccess },

    { path: '/prueba.componente', name: 'prueba.componente', component: prueba_componente },

    { path: '/catarts', name: 'catarts', component: CatArts },

]

const router = new VueRouter({
    mode: '',
    base: process.env.BASE_URL,
    routes
})

export default router