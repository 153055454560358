<template>
  <v-app id="inspire" >

    <v-system-bar
      app
      height="30"
      color="primary"
    ></v-system-bar>

    <!-- Barra principal ( HEADER ) -->
    <v-app-bar app tabs class="white elevation-0">

      <v-btn icon @click.stop="drawer = !drawer" v-if="getusuarioFetish"> <v-icon>mdi-menu</v-icon> </v-btn>

      <v-spacer></v-spacer>

      <v-img 
        src="@/assets/logo.png" 
        type="button" 
        @click="Inicio()" 
        class="mt-4"
        contain
        aspect-ratio="2"
        v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'"
        max-width="150"
      ></v-img>

      <v-spacer></v-spacer>

      <!-- Buscador en web -->
      <v-text-field
        label="Buscar"
        id="id"
        v-model="search"
        hide-details
        outlined
        dense
        append-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>

      <!-- Simbolo de cuenta -->
      <v-btn 
        fab 
        small 
        @click="Iniciar_Sesion = true" 
        v-if="!getusuarioFetish" 
        class="elevation-0 mr-2"
        color="primary"
      >
        <v-icon color="white">mdi-account</v-icon>
      </v-btn>

      <v-btn 
        text 
        small 
        color="secondary" 
        class="caption font-weight-bold mx-3"
        v-if="getusuarioFetish && $vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'"

      >
        {{getusuarioFetish.nombre}}
      </v-btn>

      <v-btn 
        text 
        small 
        color="secondary" 
        class="caption font-weight-bold mx-3"
        v-else-if="getusuarioFetish"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>

      <v-badge 
        color="error" 
        :content="getCarrito.length" 
        overlap 
        v-if="getCarrito.length > 0"
      >
        <v-btn 
          depressed 
          fab 
          small 
          @click.stop="ver_carrito()"
          color="primary"
        >
          <v-icon color="white">shopping_cart</v-icon>
        </v-btn>
      </v-badge>

      <v-btn  
        depressed 
        fab 
        small 
        v-else 
        @click.stop="ver_carrito()"
        color="primary"
      >
        <v-icon color="white">shopping_cart</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <template v-slot:extension align="right" >
        <v-spacer></v-spacer>

        <v-menu 
          v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'"
          offset-y
          v-for="(item , i) in familias"
          :key="i"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              class="text-capitalize mr-2"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.familia}}
            </v-btn>
          </template>

          <v-list dense nav >
            <template v-for="item2 in item.categorias" >
              
              <v-list-item link v-if="!item2.subcategorias.length" @click="activarMenu(item2),buscar_por_categoria(item, item2)">
                <v-list-item-title class="subtitle-1" color="pink">  
                  <span class="text-subtitle-1">{{ item2.categoria }}</span> 
                </v-list-item-title>
              </v-list-item>

              <v-list-group v-model="item2.model" :key="item2.id" 
                color="pink"
                link
                @click="activarMenu(item2)"
                :append-icon="!item2.subcategorias.length? 'mdi-arrow-right':'mdi-chevron-down'"  
                v-else
              >
                
                <template v-slot:activator >
                  <v-list-item-content >
                    <v-list-item-title class="subtitle-1" >
                      <span class="text-subtitle-1">  {{ item2.categoria }} </span> 
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item v-for="(item3, k) in item2.subcategorias" :key="k" 
                   dense link class="blue--text" 
                   @click="active = true,buscar_por_subcategoria(item,item2,item3)"
                >
                  <v-list-item-content >
                    <v-list-item-title class="subtitle-2 mx-5" > 
                      {{ item3.subcategoria }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action >
                    <v-icon small color="blue">mdi-arrow-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
      </template>
    </v-app-bar>


    <v-snackbar v-model="alert.active"  multi-line vertical top right :color="alert.color" > 
      <strong> {{ alert.text }} </strong>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" dark text @click="alert.active = false" v-bind="attrs"> Cerrar </v-btn>
      </template>
    </v-snackbar>

    <v-navigation-drawer v-model="drawer" temporary app width="300px" class="mt-1" style="border-radius:14px">
      <v-list dense nav class="py-0">
        <v-list-item two-line >
          <v-list-item-avatar tile size="30px" > 
            <img src="cursor.png" >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-primary subtitle-2 text-left" dark> 
              <span class="subtitle-2 font-weight-bold">{{ getusuarioFetish.nombre }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="gray"></v-divider>
    
      <v-list shaped >
        <template v-for="control in AppControl">
          <v-list-item
            v-for="(child, i) in control.admin"
            :key="i"
            :to="child.path"
            color="accent"
            outlined
          >
            <v-list-item-content >
              <v-list-item-title >
                <span class="font-weight-medium subtitle-2">{{ child.text }}  </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-icon small color="primary">{{ child.icon }}</v-icon> 
          </v-list-item>

        </template>
      </v-list>
  
      <v-footer absolute  class="pa-2" >
        <v-spacer></v-spacer>
        <v-btn  block  @click="cerrar_sesion()" outlined color="error"> Cerrar Sesion  </v-btn>
      </v-footer>
    </v-navigation-drawer>

    <v-navigation-drawer right temporary app v-model="$store.state.Carrito.carritovisible"  width="400px">
      <div class="pa-2"><CARRITO/></div> 
      <div class="mt-12"></div>
      <v-footer absolute color="white" class="pa-2" >
        <v-spacer></v-spacer>
        <v-btn dark block  @click="ver_carrito()" outlined color="orange" tile> Seguir comprando  </v-btn>
      </v-footer>	
    </v-navigation-drawer>   

    <v-main>
      <v-slide-y-transition mode="out-in" >
        <router-view  />
      </v-slide-y-transition>
    </v-main>

    <v-dialog v-model="Iniciar_Sesion" width="400" >
      <v-card class="pa-2">
        <v-card-actions class="py-0">
          <v-spacer></v-spacer>
          <v-btn icon @click="Iniciar_Sesion = false"><v-icon>mdi-window-close</v-icon> </v-btn>
        </v-card-actions>
        <login    v-if="login"  @login="login = $event" @sesion="Iniciar_Sesion = $event"  @alerta="alert = $event"/>
        <register v-if="!login" @login="login = $event"/>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
  import store from '@/store'
  import {mapActions , mapGetters, mapMutations} from 'vuex'
  import CARRITO  from '@/components/Carrito.vue'
  import login    from '@/views/Login/login.vue'
  import register from '@/views/Login/register.vue'
	import metodos from '@/mixins/metodos.js';

  export default {
		mixins:[metodos],
    name: 'App',
    components: {
      CARRITO,
      login,
      register,
    },

    data: () => ({
      active:false,
      tabs: 1,
      drawer: false,
      familias:[],
      Iniciar_Sesion: false,
      login : true,
      sesion: {},
			alert : { active: false, text:'', color:'error' },
      search:'',

       AppControl: [
        {
          admin: [ 
            { id:2, text: 'Detalle de la cuenta' , icon: 'mdi-arrow-right'     ,path: '/detalle.cuenta'},
            { id:3, text: 'Pedidos'              , icon: 'mdi-arrow-right'     ,path: '/pedidos'       },
            { id:4, text: 'Direcciones de envío'          , icon: 'mdi-arrow-right'     ,path: '/direcciones'   },
            //{ id:5, text: 'Direcciones de facturación'    , icon: 'mdi-arrow-right'     ,path: '/direcciones.facturacion'   },
            { id:6, text: 'Lista de deseos'      , icon: 'mdi-arrow-right'     ,path: '/deseos'    },
            ],
        },
      ],

      EstadoCarrito:false
    }),

    async created(){
      await this.consultar_menu_pagina();
      await this.configuracion_de_colores();
    },

    watch:{
      getusuarioFetish(){
         this.$store.state.Listado.historial = []
      },

      search(){
        if(this.$router.history.current.name != 'catarts'){
          this.$router.push({name:'catarts'})
        }
        this.buscar(this.search)
      }
    },

    computed:{
      ...mapGetters('Carrito',['getCarrito']),
      ...mapGetters('login',['getusuarioFetish']),
      ...mapGetters(['links']),
    },

    methods:{
      ...mapMutations(['toggleDrawer']),
      
      onClick (e, item) {
        e.stopPropagation()

        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
      },

      ...mapActions('login',['salirLogin','guardar_ciaws']),
      ...mapActions('Carrito',['limpiarCarrito']),
      ...mapActions('Listado',['buscar_productos_categoria','buscar_productos_subcategorias','guardar_historial_productos','buscar']),

      consultar_menu_pagina(){
        this.$http.get('menu.tienda').then( response =>{
          this.familias = response.body
          return true;
        }).catch( error =>{
          console.log('error', error)
        });
      },

      configuracion_de_colores(){
        this.$http.get('config.list').then(response=>{
          // console.log('CONFIG', response.body)
          let ciaws = response.body
          this.guardar_ciaws(ciaws)
          return true;
        });
      },

      Inicio(){
        this.$router.push({ name:'Home', params:{ inicio: true } }) 
      },
      irAFetichismo(){
        this.$router.push({ name:'fetichismo' }) ;
      },
      irAPerfilUsuario(){
        this.$router.push({ name:'control.perfil.usuario' }) ;
      },

      activarMenu(item2){
        // console.log(item2.subcategorias.length)
        if(item2.subcategorias.length){
          this.active = false
        }else{
          this.active = true
        }
        
      },

      buscar_por_categoria(familia, item){
        const payload = new Object({ familia:familia.familia, categoria: item, modo:1, idfamilias: familia.idfamilias });
        // this.$router.push({ name: 'listado.productos', params:{id:item.idcategorias, modo:1, payload:payload }});        
        this.buscar_productos_categoria(payload);
        this.guardar_historial_productos(payload);
        if(this.$router.currentRoute.name != 'listado.productos'){ 
          this.$router.push({ name: 'listado.productos' });
        }
      },

      buscar_por_subcategoria(familia, item1,item2){
        const payload = new Object({ familia:familia.familia, categoria: item1, subcategoria: item2, modo:2, idfamilias: familia.idfamilias});
        // this.$router.push({ name: 'listado.productos', params:{ id:item2.idsubcategorias, modo:2, payload: payload } });
        this.buscar_productos_subcategorias(payload)
        this.guardar_historial_productos(payload);
        if(this.$router.currentRoute.name != 'listado.productos'){  
          this.$router.push({ name: 'listado.productos' });
        }
      },

      cerrar_sesion(){
        this.alert  = { active: true, text: `HASTA LUEGO, VUELVE PRONTO`, color :'success' , vertical:true  };
        this.drawer = false
        this.salirLogin();
        this.limpiarCarrito();
        if(this.$router.currentRoute.name != 'Home'){ 
          this.$router.push({ name: 'Home' });        
        }
      },
    }

  };
</script>

<style >

  .shadowCard{
    border-radius: 8px !important; 
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.1)  !important;
  }
 
</style>
  