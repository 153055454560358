<template>
	<v-row justify="center" class="ma-0">
		<v-col xs="12" v-if="getCarrito.length ===  0" >
			<v-alert type="info"> Tu carrito esta vacio. </v-alert>
		</v-col>

		<v-col xs="12" v-if="getCarrito.length">
			<v-card outlined class="pa-3">
				<v-card-title primary-title class="py-0">
					<span><v-icon left>mdi-shopping</v-icon>Artículos</span>
					<v-spacer></v-spacer> 
					<span class="text-right "><h3><strong>{{ totalArt }}</strong></h3></span>
				</v-card-title>
			</v-card>
		</v-col>

		<v-col cols="12" v-if="getCarrito.length">
			<v-card  v-for="(item , i) in getCarrito" :key="i" class="shadowCard">
				<v-list  three-line class="mt-1">
					<v-list-item   link>
						<v-list-item-avatar tile size="60">
							<img :src="url+item.foto" v-if="item.foto" >
							<img :src="url+photo" v-else >
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title    class="caption primary--text font-weight-black">{{ item.nomart}} </v-list-item-title>
							<v-list-item-subtitle class="caption black--text">Precio: {{ item.precio1 | currency(2) }} MX</v-list-item-subtitle>
							<v-list-item-subtitle class="caption black--text">Desc: {{ item.pjedesc }}%</v-list-item-subtitle>
							<v-list-item-subtitle class="caption black--text">Total: {{  (item.precio1 * item.cantidad) - (item.pjedesc / 100) * (item.precio1 * item.cantidad ) | currency(2) }}MX</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-card-actions>
					<v-btn x-small fab dark color="error " @click="remove(i)"><v-icon >remove</v-icon></v-btn>
					<v-spacer></v-spacer>
					<v-chip  fab  outlined>{{ item.cantidad }}</v-chip>
					<v-spacer></v-spacer>
					<v-btn x-small fab  color="primary" @click="add(i)"><v-icon >add</v-icon></v-btn>
				</v-card-actions>
			</v-card>
			<div class="mt-10"></div>
		</v-col>

		<!-- Botón para pagar -->
		<v-footer absolute color="white" class="mb-12 px-2 " v-if="getCarrito.length">
      <v-spacer></v-spacer>
      <v-btn tile dark block color="primary" :to="{ name: 'procesar.pedido' }"> 
				Pagar ahora ($ {{ total }} MXN) 
			</v-btn>
    </v-footer>
	</v-row>	
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'
  import  metodos from '@/mixins/metodos.js';

	import Vue from 'vue'
	var accounting = require("accounting");
  Vue.filter('currency', (val, dec) => {
    return accounting.formatMoney(val, '$', dec)
  })
	export default{
    mixins:[metodos],
		data(){
			return{
				iva     : 0,
				subtotal: 0,
				Movim   : [],
				// getCarrito: []
				url   : this.$http.options.root + 'fotos-epic-grass/' ,
        photo : 'nophoto.png'
			}
		},

		computed: {
			...mapGetters('Carrito',['getCarrito']),

			totalArt () {
				let items = 0
				if(this.getCarrito.length > 0){
					for(const i in this.getCarrito){
	    			items += this.getCarrito[i].cantidad 
	    		}
	    		return items
				}else{
					return 0;
				}
			},

			total () {
				let totalApagar = 0

				if(this.getCarrito.length > 0){
					for(const i in this.getCarrito){
	    			totalApagar = totalApagar + (this.getCarrito[i].precio1 * this.getCarrito[i].cantidad ) 
	    		}
	    		totalApagar = accounting.formatNumber(totalApagar,2)
	    		return totalApagar
				}else{
					return 0;
				}
			},
			recalcular(){
    		
	    }
    },

    methods: {
      ...mapActions('Carrito',['CerrarCarrito','updateCarrito']),

    	add(position){
    		this.getCarrito[position].cantidad += 1;
    		this.updateCarrito(this.getCarrito)
    	},

    	remove(position){
    		if(this.getCarrito[position].cantidad == 1){
    			this.getCarrito.splice(position,1)
    		}else{
    			this.getCarrito[position].cantidad -= 1
    		}
    		this.updateCarrito(this.getCarrito)
    	},
    },
	};
</script>
