import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandXTransition } from 'vuetify/lib/components/transitions';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-4",attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","width":"700px"},model:{value:(_vm.FINALIZACION_PEDIDO),callback:function ($$v) {_vm.FINALIZACION_PEDIDO=$$v},expression:"FINALIZACION_PEDIDO"}},[_c(VCard,{staticClass:"pa-4"},[_c(VCardText,{staticClass:"text-center font-weight-black text-h5 mt-3"},[_vm._v(" ¡Tu pedido se procesó correctamente! ")]),_c(VCard,{staticClass:"text-center",attrs:{"flat":""}},[_c(VExpandXTransition,[_c(VIcon,{staticClass:"shrink",staticStyle:{"font-size":"130px"},attrs:{"color":"success"}},[_vm._v(" mdi-check-circle-outline")])],1)],1),_c('div',{staticClass:"mt-12"}),_c(VFooter,{staticClass:"pa-2",attrs:{"absolute":"","color":"white"}},[_c(VSpacer),_c(VBtn,{attrs:{"dark":"","block":"","color":"warning","to":{ name: 'Home' }}},[_vm._v(" Seguir comprando ")])],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","width":"500px"},model:{value:(_vm.ERROR_PEDIDO),callback:function ($$v) {_vm.ERROR_PEDIDO=$$v},expression:"ERROR_PEDIDO"}},[_c(VCard,{staticClass:"pa-2"},[_c(VCardText,{staticClass:"text-center font-weight-black text-h5 mt-3"},[_vm._v(" Lo sentimos, este pedido ya se ha generado anteriormente. ")]),_c(VCard,{staticClass:"text-center",attrs:{"flat":""}},[_c(VExpandXTransition,[_c(VIcon,{staticClass:"shrink",staticStyle:{"font-size":"130px"},attrs:{"color":"error"}},[_vm._v(" mdi-close-circle-outline")])],1)],1),_c('div',{staticClass:"mt-12"}),_c(VFooter,{staticClass:"pa-2",attrs:{"absolute":"","color":"white"}},[_c(VSpacer),_c(VBtn,{attrs:{"dark":"","block":"","color":"warning","to":{ name: 'Home' }}},[_vm._v(" Regresar al inicio ")])],1)],1)],1),(_vm.overlay)?_c('overlay'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }