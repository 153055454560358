<template>
  <v-container fluid >
     <v-snackbar v-model="alert.active"  multi-line vertical top right :color="alert.color" > 
        <strong> {{alert.text}} </strong>
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click="alert.active = false" v-bind="attrs"> Cerrar </v-btn>
        </template>
      </v-snackbar>

    <v-card-text class="py-0 text-center"  v-if="login">
      <img
        src="@/assets/logo.png"
        class=" pa-2 mb-5"
        width="100%" 
        transparent
        loading="lazy"
      >
      </img>
    </v-card-text>

    <v-card-text class="py-0"  v-if="login">
      <template v-if="!OlvideContra">
        <v-form ref="form" v-model="valid" >
          <label>Correo eléctronico</label>
          <v-text-field
            v-model="email" 
            append-icon="email" 
            placeholder="email@example" 
            required
            outlined
            type="text"
            dense
            :rules="emailRules"
          >
          </v-text-field>

          <label>Contraseña</label>
          <v-text-field
            v-model="password"
            append-icon="lock"
            :append-icon="show1 ? 'visibility' : 'visibility_off'"
            :rules="passwordRules"
            :type="show1 ? 'text' : 'password'"
            placeholder="**********"
            @click:append="show1 = !show1"
            @keyup.enter="ingresar"
            dense
            clearable
            outlined
            required
          ></v-text-field>
        </v-form>
      </template>
      
      <div v-if="OlvideContra" >
         <olvidaContra @modal="OlvideContra = $event" class="pa-0"/>
      </div>

    </v-card-text>

    <v-card-actions v-if="!OlvideContra">
      <v-btn 
        :loading="iniciar"
        persistent
        color="primary"  
        block 
        @click="ingresar" 
      >
        Iniciar sesión
      </v-btn>
    </v-card-actions>


    <v-card-actions v-if="!OlvideContra" class="text-subtitle-1">
      ¿No tienes cuenta? <v-card @click="$emit('login',false)" class="elevation-0 transparent primary--text ml-2"> Registrarse</v-card>
    </v-card-actions>


    <v-footer v-if="!OlvideContra" class="#f6f9fc text-caption text-center" color="#f6f9fc">
      <v-spacer></v-spacer>
      Olvide mi contraseña <v-card @click="OlvideContra = true"  class="elevation-0 transparent primary--text ml-2">Clic aquí!</v-card>
      <v-spacer></v-spacer>
    </v-footer>
  </v-container>
</template>

<script>
  import {mapActions , mapGetters} from 'vuex'
  import olvidaContra from '@/views/Login/olvida_contra.vue'
  var md5     = require('md5')

  export default {
    components: {
      olvidaContra
    },
    data(){
      return{
        login: true, 
        password: '',
        email:'',
        iniciar: false,
       
        show1: false,

				alert: { active: false, text:'', color:'error' },

        valid: false,
        emailRules    :  [v => !!v || 'requerido'],
        passwordRules    :  [v => !!v || 'requerido'],
        OlvideContra: false
      }
    },

   

    methods:{
      ...mapActions('login',['refreshDatos']),

      ingresar (){
        this.iniciar = true;
        const payload = new Object();
              payload.email    = this.email;
              payload.password = md5(this.password)

        // this.validarUser(payload).then(response => {
        //   console.log('response en login', response)

        // }).catch( error =>{
        //   console.log('error en login', error)
        //    this.alert = { active: true, text: 'UPS, ALGO SALIO MAL VERIFICA TU USUARIO Y CONTRASEÑA', color:'error'};
        // }).finally(()=>{
        //   this.iniciar = false
        // })

        this.$http.post('session.cliente', payload).then( response =>{
          if(response.body){
            const alerta = new Object({ active: true, text: 'BIENVENIDO' + ' ' + response.body.nombre, color:'blue'});
            this.$emit('alerta',alerta ); this.$emit('sesion',false );
            this.refreshDatos(response.body)
          }else{
            this.alert = { active: true, text: 'UPS, ALGO SALIO MAL VERIFICA TU USUARIO Y CONTRASEÑA', color:'error'};
          } 
        }).catch( error =>{
				  this.alert = { active: true, text: error.body.message, color:'error'};
        }).finally( ()=>{
          this.iniciar = false
        })
        
      }
    }
  };


</script>



  