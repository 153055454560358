<template>
	<v-container >
    <v-row class="py-0">

      <v-col cols="12" class="py-0">
        <!-- Titulo -->
        <div class="d-flex align-center">
          <v-avatar
            size="44"
            rounded
            class="elevation-0 transparent"
          >
            <v-icon large color="black">mdi-cupcake</v-icon>
          </v-avatar>
          <div class="ms-3 mt-4">
            <p class="text-xs mb-0">
              <h2 class="font-weight-bold">Productos más vendidos</h2>
            </p>
          </div>
        </div>

        <v-sheet
          class="mx-auto transparent"
          elevation="0"
          color="#f6f9fc"
        >
          <v-slide-group
            v-model="model"
            class="pa-4"
            active-class="success"
            show-arrows
          >
            <template v-slot:prev="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                fab
                small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>

            <template v-slot:next="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                fab
                small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            
            <v-slide-item v-for="(item, i) in productos" :key="i" >
              <v-hover
                v-slot="{ hover }"
              >
                <v-card  
                  class="shadowCard lateral ma-4" 
                  :width="$vuetify.breakpoint.name != 'xs'? '300px': '200px' "
                  outlined
                  :elevation="hover ? 8 : 0"
                  :class="{ 'on-hover': hover }"
                >
                  <!-- Descuento mostrado -->
                  <v-btn 
                    color="red" 
                    dark
                    tile
                    absolute
                    top
                    left
                    class="v-btn--descuento text-caption"
                    small
                    v-if="item.pjedesc > 0"
                  >
                    {{ item.pjedesc }}% Descuento
                  </v-btn>

                  <!-- Ver el artículo -->
                  <v-btn 
                    icon
                    absolute
                    top
                    right
                    class="v-btn--descuento text-caption"
                    small
                    rounded
                    @click="ver(item)"
                  >
                    <v-icon color="black">mdi-eye</v-icon>
                  </v-btn>

                  <!-- Pañadir a la lista de deseos -->
                  <v-tooltip bottom v-if="getusuarioFetish">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn 
                        icon
                        absolute
                        top
                        right
                        class="v-btn--descuento text-caption mt-7"
                        small
                        rounded 
                        v-bind="attrs" 
                        v-on="on" 
                        @click="aniadir_lista_deseos(item)"
                      >
                        <v-icon color="red">mdi-heart</v-icon> 
                      </v-btn>
                    </template>
                    <span>Añadir a lista de deseos</span>
                  </v-tooltip>

                  <!-- IMAGEN PRINCIPAL -->
                  <v-img 
                    :src="url + item.foto" 
                    height="250px" 
                    contain 
                    v-if="item.foto"
                    @click="ver(item)"
                    class="white"
                  >
                  </v-img>
                  
                  <!-- SI NO HAY IMAGEN INCERTO DEFAULT-->
                  <v-img 
                    :src="`${url + photo}`" 
                    height="250px"
                    contain 
                    v-else 
                    @click="ver(item)" 
                    color="secondary" 
                  />
                
                  <v-card-text>
                    <v-row>
                      <!-- NOMBRE DEL ARTÍCULO -->
                      <v-col cols="12" class="pb-0 pt-0 black--text tamanio_name">
                        <span 
                          class="text-subtitle-1 font-weight-bold black--text"
                        > 
                          {{item.nomart}}
                        </span>
                      </v-col>

                      <v-col cols="12" class="pb-0 pt-0 black--text">
                        <b>SABOR:</b> <v-chip x-small color="#00FF13"><b> UVA </b></v-chip>

                        <br/>

                        <!-- <b>EFECTO:</b> <v-chip x-small color="#00FF13"><b> Somnoliente </b></v-chip> -->
                      </v-col>

                      <!-- Aquí vamos a poner las descripciones -->
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="6" class="pl-1 pr-1">
                            <v-card class="elevation-0" color="primary">
                              <v-card-text class="white--text pa-1">
                                Personas
                                <br/>
                                12
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="6" class="px-0">
                            <v-card class="elevation-0" color="#ffaeba">
                              <v-card-text class="black--text pa-1">
                                Presentación
                                <br/>
                                PZA
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <!-- <v-col cols="4" class="pr-1 pl-1">
                            <v-card class="elevation-0" color="#CC9CFF" dark>
                              <v-card-text class="white--text pa-1">
                                CBD
                                <br/>
                                12.4%
                              </v-card-text>
                            </v-card>
                          </v-col> -->
                        </v-row>
                      </v-col>

                      <!-- <v-col cols="12" class="pt-0 pb-1">
                        <v-row class="black--text">
                          <v-col>
                            Calmante
                          </v-col>
                          <v-spacer></v-spacer> 
                          <v-col>
                            Energizante
                          </v-col>
                        </v-row>

                        <v-slider
                          v-model="slider"
                          color="black"
                          hide-details
                        >
                          <template v-slot:prepend>
                            <span><h3>🫠</h3></span>
                          </template>

                          <template v-slot:append>
                            <span><h3>😁</h3></span>
                          </template>
                        </v-slider>
                      </v-col>

                      <v-col cols="12" class="pt-0 pb-2">
                        <v-row class="black--text">
                          <v-col>
                            Bajo THC
                          </v-col>
                          <v-spacer></v-spacer> 
                          <v-col>
                            Alto THC
                          </v-col>
                        </v-row>

                        <v-slider
                          v-model="slider"
                          color="black"
                          hide-details
                        >
                          <template v-slot:prepend>
                            <v-icon
                              color="black"
                            >
                              mdi-minus
                            </v-icon>
                          </template>

                          <template v-slot:append>
                            <v-icon
                              color="black"
                            >
                              mdi-plus
                            </v-icon>
                          </template>
                        </v-slider>
                      </v-col> -->

                      <v-col cols="12" class="pt-0 pb-1">
                        <!-- Precio normal -->
                        <div v-if="item.pjedesc === 0">
                          <h3 class="black--text mr-2 mb-0" v-if="item.pjedesc === 0">
                            {{ item.precio1 | currency(2) }} MX 
                          </h3>
                        </div>
                        
                        <!-- Precio con descuento -->
                        <div v-else class="grey--text mr-2 mb-0"> 
                          <h3 class="red--text mr-2 mb-0">
                            <span class="text-decoration-line-through grey--text" v-if="item.pjedesc > 0"> 
                              ${{ item.precio1 }} 
                            </span>
                            {{ (item.precio1) - (item.pjedesc / 100) * (item.precio1 ) | currency(2) }} MX
                          </h3> 
                        </div>
                      </v-col>
                
                    </v-row>
                  </v-card-text>

                  <v-card-actions class="subtitle-1 font-weight-bold secondary--text" >
                    <v-btn 
                      color="primary" 
                      block
                      dark
                      tile
                      button
                      right
                      @click="agregar_a_carrito(item)"
                    >
                      Agregar
                      <v-icon left >mdi-plus</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>

    </v-row>
	</v-container>

</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
	import metodos from '@/mixins/metodos.js';
  export default {
		mixins:[metodos],
    components: {
    },
    data(){
      return{
        model: null,
        url   : this.$http.options.root + 'fotos-epic-grass/' ,
        photo : 'nophoto.png',
        productos:[]
      }
    },

    computed:{
      ...mapGetters('login',['getusuarioFetish']),

      tamanioPantalla () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return this.$vuetify.breakpoint.height -50
          break;
          case 'sm': 
            return this.$vuetify.breakpoint.height -50
          break;
          case 'md':
            return this.$vuetify.breakpoint.height -50
          break;
          case 'lg':
            return this.$vuetify.breakpoint.height -50
          break;
          case 'xl':
            return this.$vuetify.breakpoint.height -50
          break;
        }
      },
    },

    created(){
      this.init();
    },

     methods:{
      ...mapActions('Carrito',['carritoadd','CerrarCarrito']),
      init(){
        // this.$http.get('obtener.articulos.random').then( response =>{
        this.$http.get('obtener.novedades').then( response =>{
          this.productos = response.body
        })
      },
      agregar_a_carrito(item){
        const payload = new Object({
          id        : item.id,
          codigo    : item.codigo,
          nomart    : item.nomart, 
          precio1   : item.precio1,
          precio2   : item.precio2,
          pjedesc   : item.pjedesc,
          descrip   : item.descrip,
          descripLarga : item.descripLarg,
          largo     : item.largo,
          ancho     : item.ancho,
          alto      : item.alto,
          peso      : item.peso,
          foto      : item.foto,
          envoltorio: item.envoltorio,
          cantidad  : 1
        })

        this.carritoadd(payload).then( response =>{
          // this.alerta = { activo: true, texto:`El producto se agrego al carrito`, color:'blue' };
          this.ver_carrito();
        })
      },
      
      añadir_lista_deseos(item){
        const payload = new Object({
          id_cliente: this.getusuarioFetish.idcliente,
          id_producto: item.id,

        })
        this.aniadir_producto_lista_deseos(payload).then(response =>{
          this.alerta = { activo:true, texto: response, color:'blue'}
        }).catch( error =>{
          this.alerta = { activo:true, texto: error, color:'error'}
        })
      },

      ver (item){
        this.$router.push({ name: 'detalle.del.producto',params:{id:item.id}})
      }
    } 
    
  }
</script>
<style>
  .lateral .v-btn--example {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
    padding: 0px !important;
    min-width: 50px !important;
  }

  .lateral .v-btn--descuento{
    bottom: 0;
    position: absolute;
    margin: 0 0 0px 0px;
    min-width: 50px !important;
    z-index: 1;
  }

  .tamanio_name{
    max-height: 100px;
    min-height: 70px;
  }
</style>