<template>
	<v-container fluid >
    <v-row >
      <v-footer class="transparent">

        <v-row justify="center">
          <v-col cols="12">
            <v-card-text >
              <v-avatar  
                v-for="(item, i ) in redes_sociales" :key="i" 
                :color="item.color"
                class="mx-4"
              >
                <a :href="item.path" target="_blank" style="text-decoration: none">
                  <v-icon dark> {{ item.icono }} </v-icon>
                </a>
              </v-avatar>
            </v-card-text>
            <v-divider ></v-divider>
          </v-col>

          <v-col cols="12" class="py-0 ma-4">
            <v-card-text class="subtitle-1 " >
              Ky-Fetish es un lugar en donde podrás disfrutar, explorar, conocer cada zona de placer y elegir 
              libremente como hacerlo. 
              Somos una tienda discreta y segura. 
              Siéntete cómodo al descubrir el mayor nivel de satisfacción y experiencias únicas.
            </v-card-text>
          </v-col>

        </v-row>

        <v-card-text >
          <a href="www.sofsolution.com" class="white--text"> {{ new Date().getFullYear() }} — <strong>Sofsolution</strong> </a>
        </v-card-text>
      </v-footer>

    </v-row>
	</v-container >
</template>

<script>
  import {mapActions , mapGetters} from 'vuex'

  export default {
    data: () => ({
      redes_sociales:[]
    }),

    computed:{
      ...mapGetters('login',['CIAWS']),
    },

    created(){
      this.init()
    },

    methods:{
      init(){
        this.redes_sociales = [
          {
            id:1, 
            nombre:'Facebook', 
            icono: 'mdi-facebook', 
            color: "blue", 
            path: 'https://www.facebook.com/zulcake/?locale=es_LA' // this.CIAWS.facebook
          },
          { 
            id: 2, 
            nombre:'Instagram', 
            icono: 'mdi-instagram', 
            color: "pink", 
            path: this.CIAWS.twitter
          }
        ]
      }
    }


  }
</script>