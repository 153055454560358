<template>
	<v-container fluid>
    <v-row class="py-0 ">

      <v-btn color="accent" style="display: none" class="ir-arriba " dark fab fixed bottom right>
        <v-icon top>keyboard_arrow_up</v-icon>
      </v-btn>
      
      <v-col cols="12" class="pa-0 py-0"> 
        <BANNER/>  
      </v-col>

      <v-col cols="12" class="pa-0"> 
        <PRODUCTOS class="pa-3"/>  
      </v-col>

      <v-col cols="12" class="pa-0 mt-12"> 
        <CELANUNCIO v-if="this.$vuetify.breakpoint.name === 'xs'"/>  
        <ANUNCIO  v-else/> 
      </v-col>

      <v-col cols="12"> 
        <PRODMASVEND class="py-0"/>  
      </v-col>
      
      <v-col cols="12" class="mt-1  text-center" > 
          <FOOTER />  
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import $ from 'jquery'
  import BANNER      from '@/components/banner.vue'
  import PRODUCTOS   from '@/components/productos.vue'
  import ANUNCIO     from '@/components/anuncio.vue'
  import CELANUNCIO  from '@/components/cel_anuncio.vue'
  import PRODMASVEND from '@/components/producto_mas_vendido.vue'
  import FOOTER      from '@/components/footer.vue'
  import CATEGORIAS  from '@/components/categorias.vue'


  export default {
    components: {
      BANNER,
      PRODUCTOS,
      ANUNCIO,
      CELANUNCIO,
      PRODMASVEND,
      FOOTER,
      CATEGORIAS
    },
    data () {
      return {
       
      }
    },

    created(){
      if(this.$route.params.inicio){
        // this.init()
      }
    },

    methods:{
      // init() {
      //   // $('body, html').animate({ scrollTop: '0px' }, 300);

      // }
    },

    mounted(){
			$(document).ready(function(){
				$('.ir-arriba').click(function(){
					$('body, html').animate({
						scrollTop: '0px'
					}, 300);
				});
			});
  	},

  }
</script>


<style >

 /* @import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap'); */
  /*@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');*/
  
  /* .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .8;
    position: absolute;
    width: 100%;
  }*/

  /*#contenido {
     display: -webkit-box;
    display: -moz-box;
    display: box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 4;
  } */

   /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #E2E2E2;
    /* background: transparent; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #C3C3C3;
  }

  @media only screen and (max-width: 600px){
    ::-webkit-scrollbar{
      width: 1px;
    }
  }

  #titulo{
   /* font-family: 'Parisienne', cursive; */
    color: #fff;
    font-size: 40px;
    line-height: 55px;
    text-shadow:
      0 0 5px rgb(208, 99, 27),
      0 0 10px rgb(255, 183, 0),
      0 0 20px rgb(255, 111, 0),
      0 0 40px rgb(127, 74, 38),
      0 0 80px rgb(127, 81, 38),
      0 0 90px rgb(127, 88, 38),
      0 0 100px rgb(127, 106, 38),
      0 0 140px rgb(127, 102, 38),
      0 0 180px rgb(127, 94, 38); 
  }

  #acceso{
    /*font-family: 'Parisienne', cursive; */
    color: #fff;
    font-size: 60px;
    line-height:60px;
    text-shadow:
      0 0 5px rgb(211, 211, 211),
      0 0 10px rgba(0,178,255,1),
      0 0 20px rgba(0,178,255,1),
      0 0 40px rgba(38,104,127,1),
      0 0 80px rgba(38,104,127,1),
      0 0 90px rgba(38,104,127,1),
      0 0 100px rgba(38,104,127,1),
      0 0 140px rgba(38,104,127,1),
      0 0 180px rgba(38,104,127,1);
  }

  @media only screen and (max-width: 600px){
    .titulo, #acceso{
      font-size: 50px;
    }
  }

</style>

