import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCarousel,{attrs:{"cycle":"","height":_vm.tamanioPantalla,"hide-delimiter-background":"","hide-delimiters":"","show-arrows-on-hover":""},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"white"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-chevron-left ")])],1)]}},{key:"next",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"white"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-chevron-right ")])],1)]}}])},_vm._l((_vm.banners),function(item,i){return _c(VCarouselItem,{key:i,attrs:{"src":_vm.url+item.nombanner,"contain":""}})}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }