import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c(VOverlay,[_c(VCard,{attrs:{"width":"400px","color":"darken"}},[_c(VCardText,{staticClass:"subtitle-1",attrs:{"align":"center"}},[_vm._v(" Guardando Información ")]),_c(VProgressLinear,{attrs:{"indeterminate":"","color":"info","height":"6","persistent":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }