<template>
	<v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-carousel cycle :height="tamanioPantalla" hide-delimiter-background hide-delimiters show-arrows-on-hover>
          <template v-slot:prev="{ on, attrs }">
            <v-btn icon large color="white" v-bind="attrs" v-on="on" >  <v-icon> mdi-chevron-left </v-icon> </v-btn>  
            </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn icon large color="white" v-bind="attrs" v-on="on"> <v-icon> mdi-chevron-right </v-icon> </v-btn>
          </template>
          <v-carousel-item  v-for="(item, i) in banners" :key="i" :src="url+item.nombanner" contain >
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container >
</template>

<script>
  export default {
    components: {

    },

    data () {
      return {
        url    : this.$http.options.root + 'fotos-epic-grass/' ,
        banners:[],
      }
    },

    computed:{
      tamanioPantalla () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
          return 'auto';
            // return this.$vuetify.breakpoint.height 
          break;
          case 'sm': 
          return '250px'
            // return this.$vuetify.breakpoint.height -110
          break;
          case 'md':
          return '450px';

            // return this.$vuetify.breakpoint.height -110
          break;
          case 'lg':
          return '350px';
            // return this.$vuetify.breakpoint.height
          break;
          case 'xl':
          return '350px'
            // return this.$vuetify.breakpoint.height -110
          break;
        }
      },
    },

    created(){
      this.init();
    },

    methods:{
      init(){
        this.$http.get('banner.list').then( response =>{
          this.banners = response.body;
          // console.log(response.body);
        }).catch( error =>{
          console.log('error banner', error.body)          
        })
      }
    }
  }
</script>