<template>
	<v-container>
    <v-row >
      <v-col cols="12" sm="5" md="4" lg="3" xl="2"  v-for="(item, i) in listado_productos" :key="i">
        <v-hover
          v-slot="{ hover }"
        >
          <!-- Contenido del material -->
          <v-card 
            class="lateral" 
            height="100%"
            outlined
            :elevation="hover ? 8 : 0"
            :class="{ 'on-hover': hover }"
          >
            <!-- Descuento mostrado -->
            <v-btn 
              color="red" 
              dark
              tile
              absolute
              top
              left
              class="v-btn--descuento text-caption"
              small
              v-if="item.pjedesc > 0"
            >
              {{ item.pjedesc }}% Descuento
            </v-btn>

            <!-- Ver el artículo -->
            <v-btn 
              icon
              absolute
              top
              right
              class="v-btn--descuento text-caption"
              small
              rounded
              @click="ver(item)"
            >
              <v-icon color="black">mdi-eye</v-icon>
            </v-btn>

            <v-tooltip bottom v-if="getusuarioFetish">
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  icon
                  absolute
                  top
                  right
                  class="v-btn--descuento text-caption mt-7"
                  small
                  rounded 
                  v-bind="attrs" 
                  v-on="on" 
                  @click="aniadir_lista_deseos(item)"
                >
                  <v-icon color="red">mdi-heart</v-icon> 
                </v-btn>
              </template>
              <span>Añadir a lista de deseos</span>
            </v-tooltip>


            <!-- IMAGEN PRINCIPAL -->
            <v-img 
              :src="url + item.foto" 
              height="250px" 
              contain 
              v-if="item.foto"
              @click="ver(item)"
              class="white"
            >
            </v-img>
            
            <!-- SI NO HAY IMAGEN INCERTO DEFAULT-->
            <v-img 
              :src="`${url + photo}`" 
              height="250px"
              contain 
              v-else 
              @click="ver(item)" 
              color="secondary" 
            />

            <!-- CONTENIDO DEL PRODUCTO -->
            <v-card-text class="pb-4">
              <v-row>

                <!-- NOMBRE DEL ARTÍCULO -->
                <v-col cols="12" class="pb-0 pt-0 black--text tamanio_name">
                  <span 
                    class="text-subtitle-1 font-weight-bold black--text"
                  > 
                    {{item.nomart}}
                  </span>
                </v-col>

                <!-- <v-col cols="12" class="pb-0 pt-0 black--text"> -->
                  <!-- <b>Evento:</b> <v-chip x-small color="grey lighten-3"><b> Vainilla </b></v-chip> -->

                  <!-- <br/> -->
                  

                  <!-- <b>EFECTO:</b> <v-chip x-small color="#00FF13"><b> Somnoliente </b></v-chip> -->
                <!-- </v-col> -->

                <!-- Aquí vamos a poner las descripciones -->
                <!-- <v-col cols="12">
                  <v-row>
                    <v-col cols="6" class="pl-1 pr-1">
                      <v-card class="elevation-0" color="primary">
                        <v-card-text class="white--text pa-1">
                          Personas
                          <br/>
                          12
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="6" class="px-0">
                      <v-card class="elevation-0" color="#ffaeba">
                        <v-card-text class="black--text pa-1">
                          Presentación
                          <br/>
                          PZA
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <<v-col cols="4" class="pr-1 pl-1">
                      <v-card class="elevation-0" color="#CC9CFF" dark>
                        <v-card-text class="white--text pa-1">
                          CBD
                          <br/>
                          12.4%
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col> -->

                <!-- <v-col cols="12" class="pt-0 pb-1">
                  <v-row class="black--text">
                    <v-col>
                      Calmante
                    </v-col>
                    <v-spacer></v-spacer> 
                    <v-col>
                      Energizante
                    </v-col>
                  </v-row>

                  <v-slider
                    v-model="slider"
                    color="black"
                    hide-details
                  >
                    <template v-slot:prepend>
                      <span><h3>🫠</h3></span>
                    </template>

                    <template v-slot:append>
                      <span><h3>😁</h3></span>
                    </template>
                  </v-slider>
                </v-col>

                <v-col cols="12" class="pt-0 pb-2">
                  <v-row class="black--text">
                    <v-col>
                      Bajo THC
                    </v-col>
                    <v-spacer></v-spacer> 
                    <v-col>
                      Alto THC
                    </v-col>
                  </v-row>

                  <v-slider
                    v-model="slider"
                    color="black"
                    hide-details
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color="black"
                      >
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon
                        color="black"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider>
                </v-col> -->

                <v-col cols="12" class="pt-0 pb-1">
                  <!-- Precio normal -->
                  <div v-if="item.pjedesc === 0">
                    <h3 class="black--text mr-2 mb-0" v-if="item.pjedesc === 0">
                      {{ item.precio1 | currency(2) }} MX 
                    </h3>
                  </div>
                  
                  <!-- Precio con descuento -->
                  <div v-else class="grey--text mr-2 mb-0"> 
                    <h3 class="red--text mr-2 mb-0">
                      <span class="text-decoration-line-through grey--text" v-if="item.pjedesc > 0"> 
                        ${{ item.precio1 }} 
                      </span>
                      {{ (item.precio1) - (item.pjedesc / 100) * (item.precio1 ) | currency(2) }} MX
                    </h3> 
                  </div>
                </v-col>

              </v-row>
            </v-card-text>
          
            <v-card-actions class="subtitle-1 font-weight-bold secondary--text" >
              <v-btn 
                color="primary" 
                tile
                dark
                block
                button
                right
                @click="agregar_a_carrito(item)"
              >
                Agregar
                <v-icon left >mdi-plus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
	</v-container >

</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
	import  metodos from '@/mixins/metodos.js';

  export default {
		mixins:[metodos],
    
    props:[
			'listado_productos'
	  ],

    data(){
      return{
        satisfactionEmojis: ['🫠', '🫠', '🫠', '🫠', '🫠', '😁', '😁', '😁', '😁', '😁'],
        slider: 45,
        model: null,
        productos:[],
        alerta: { activo: false, texto:'', color:'error' },
        url   : this.$http.options.root + 'fotos-epic-grass/' ,
        photo : 'nophoto.png'
      }
    },
   
    computed:{
      ...mapGetters('login',['getusuarioFetish']),
      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -50
      },
    },

    created(){
      this.init();
    },

    methods:{
      ...mapActions('Carrito',['carritoadd','CerrarCarrito']),

      init(){
        this.$http.get('obtener.articulos.random').then( response =>{
          this.productos = response.body
        })
      },

      agregar_a_carrito(item){
        const payload = new Object({
          id           : item.id,
          codigo       : item.codigo,
          nomart       : item.nomart, 
          precio1      : item.precio1,
          precio2      : item.precio2,
          pjedesc      : item.pjedesc,
          descrip      : item.descrip,
          descripLarga : item.descripLarg,
          largo        : item.largo,
          ancho        : item.ancho,
          alto         : item.alto,
          peso         : item.peso,
          foto         : item.foto,
          envoltorio   : item.envoltorio,
          cantidad     : 1
        })

        this.carritoadd(payload).then( response =>{
          this.ver_carrito();
        })
      },
      aniadir_lista_deseos(item){
        const payload = new Object({
          id_cliente: this.getusuarioFetish.idcliente,
          id_producto: item.id,

        })
        this.aniadir_producto_lista_deseos(payload).then(response =>{
          this.alerta = { activo:true, texto: response, color:'blue'}
        }).catch( error =>{
          this.alerta = { activo:true, texto: error, color:'error'}
        })
      },

      ver (item){
        this.$router.push({ name: 'detalle.del.producto',params:{id:item.id}})
      }
    } 
    
  }
</script>

<style>
  .lateral .v-btn--example {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
    padding: 0px !important;
    min-width: 50px !important;
  }

  .lateral .v-btn--descuento{
    bottom: 0;
    position: absolute;
    margin: 0 0 0px 0px;
    min-width: 50px !important;
    z-index: 1;
  }

  .tamanio_name{
    max-height: 100px;
    min-height: 70px;
  }
</style>