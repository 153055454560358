import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"mb-12"},[_c(VRow,[_c(VCarousel,{attrs:{"cycle":"","height":"auto","hide-delimiter-background":"","hide-delimiters":"","show-arrows-on-hover":""},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"white"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-chevron-left ")])],1)]}},{key:"next",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"white"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-chevron-right ")])],1)]}}])},_vm._l((_vm.anuncios),function(item2,j){return _c(VCarouselItem,{key:j,attrs:{"src":item2.foto,"contain":""}},[_c(VRow,{staticClass:"fill-height",attrs:{"align":"end","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"secondary","large":"","rounded":"","elevation":"12"},on:{"click":function($event){return _vm.irA(item2.path)}}})],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }