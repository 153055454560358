<template>
	<v-container>

    <v-row>
    	<v-col cols="12" class="py-0 pt-10">
        <!-- Titulo -->
        <div class="d-flex align-center">
          <v-avatar
            size="44"
            rounded
            class="elevation-0 transparent"
          >
            <v-img src="@/assets/iconos/categorias.png" width="42" height="42"/>
          </v-avatar>
          <div class="ms-3 mt-4">
            <p class="text-xs mb-0">
              <h2 class="font-weight-bold">Categorías</h2>
            </p>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="3" lg="3" xl="2" v-for="(catego, i) in categorias" :key="i">
      	<v-card 
          class="elevation-0 transparent text-center" 
          align="center" 
          style="border-radius: 1000px !important;"
          @click="buscar_por_categoria(catego)"
        >
      		<v-avatar
            size="150"
            class="white elevation-6"
          >
            <img :src="url + catego.foto" v-if="catego.foto"/>
            <img :src="url + 'nophoto.png'" v-else/>
          </v-avatar>
          <v-card-actions class="text-center pt-0 pb-0">
            <v-spacer></v-spacer>
            <p class="text-xs mb-0 text-center" style="height: 60px;">
              <h5 class="font-weight-bold text-center">{{ catego.categoria }} </h5>
            </p>
            <v-spacer></v-spacer>
          </v-card-actions>
      	</v-card>
      </v-col>
    </v-row>
  </v-container >
</template>

<script>
  import {mapActions , mapGetters, mapMutations} from 'vuex'

  export default {
    components: {

    },

    data () {
      return {
        url    : this.$http.options.root + 'fotos-epic-grass/' ,
        categorias:[],
        familias:[]
      }
    },

    created(){
      this.init();
      this.consultar_menu_pagina()
    },

    methods:{

      ...mapActions('Listado',['buscar_productos_categoria','buscar_productos_subcategorias','guardar_historial_productos','buscar']),

      init(){
        this.$http.get('categorias.activo').then( response =>{
          this.categorias = response.body;
        }).catch( error =>{
          console.log('error banner', error.body)          
        })
      },

      consultar_menu_pagina(){
        this.$http.get('menu.tienda').then( response =>{
          this.familias = response.body
          console.log( this.familias )
          return true;
        }).catch( error =>{
          console.log('error', error)
        });
      },

      // buscar_por_categoria(familia, item){
      buscar_por_categoria( catego ){
        const familia = this.familias.find( el=> el.idfamilias == catego.idfamilias )
        const item    = familia.categorias.find( el=> el.idcategorias == catego.idcategorias )
        const payload = new Object({ familia:familia.familia, categoria: item, modo:1, idfamilias: familia.idfamilias });
        // this.$router.push({ name: 'listado.productos', params:{id:item.idcategorias, modo:1, payload:payload }});        
        this.buscar_productos_categoria(payload);
        this.guardar_historial_productos(payload);
        if(this.$router.currentRoute.name != 'listado.productos'){ 
          this.$router.push({ name: 'listado.productos' });
        }
      },
    }
  }
</script>