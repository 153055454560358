import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pb-12 pa-3 py-0"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VSnackbar,{attrs:{"multi-line":"","vertical":"","top":"","right":"","color":_vm.alerta.color,"timeout":2000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.alerta.activo = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.alerta.activo),callback:function ($$v) {_vm.$set(_vm.alerta, "activo", $$v)},expression:"alerta.activo"}},[_c('strong',{staticClass:"subtitle-1 font-weight-black"},[_vm._v(" "+_vm._s(_vm.alerta.texto)+" ")])]),_c(VCol,{staticClass:"py-0 pt-10",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"elevation-0 transparent",attrs:{"size":"44","rounded":""}},[_c(VImg,{attrs:{"src":require("@/assets/iconos/estrellas.png"),"width":"42","height":"42"}})],1),_c('div',{staticClass:"ms-3 mt-4"},[_c('p',{staticClass:"text-xs mb-0"}),_c('h2',{staticClass:"font-weight-bold"},[_vm._v("El regalo perfecto")]),_c('p')])],1)]),_c('card_articulo',{attrs:{"listado_productos":_vm.listado_productos}}),_c(VBtn,{attrs:{"fab":"","fixed":"","bottom":"","left":"","color":"primary","onClick":"history.go(-1);"}},[_c(VIcon,[_vm._v("mdi-arrow-left-thick")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }