import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VFooter,{staticClass:"transparent"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCardText,_vm._l((_vm.redes_sociales),function(item,i){return _c(VAvatar,{key:i,staticClass:"mx-4",attrs:{"color":item.color}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":item.path,"target":"_blank"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.icono)+" ")])],1)])}),1),_c(VDivider)],1),_c(VCol,{staticClass:"py-0 ma-4",attrs:{"cols":"12"}},[_c(VCardText,{staticClass:"subtitle-1"},[_vm._v(" Ky-Fetish es un lugar en donde podrás disfrutar, explorar, conocer cada zona de placer y elegir libremente como hacerlo. Somos una tienda discreta y segura. Siéntete cómodo al descubrir el mayor nivel de satisfacción y experiencias únicas. ")])],1)],1),_c(VCardText,[_c('a',{staticClass:"white--text",attrs:{"href":"www.sofsolution.com"}},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("Sofsolution")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }