<template>
	<v-container>
    <v-row  justify="space-around">

      <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" :timeout="2000"  > 
        <strong class="subtitle-1 font-weight-black"> {{alerta.texto}} </strong>
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
        </template>
      </v-snackbar>
      
      <!-- Titulo de la sección -->
      <v-col cols="12" class="text-center py-0"> 
        <div class="d-flex align-center">
          <v-avatar
            size="44"
            rounded
            class="elevation-0 transparent"
          >
            <v-icon large color="black">mdi-cupcake</v-icon>
          </v-avatar>
          <div class="ms-3 mt-4">
            <p class="text-xs mb-0">
              <h2 class="font-weight-bold">Shop All Products</h2>
            </p>
          </div>
        </div>
      </v-col>

      <!-- Productos -->
      <card_articulo :listado_productos="productos"/>
    </v-row>
	</v-container >

</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
	import  metodos from '@/mixins/metodos.js';
  import card_articulo from '@/components/card_articulo.vue'

  export default {
		mixins:[metodos],
    components: {
      card_articulo
    },

    data(){
      return{
        model: null,
        productos:[],
        alerta: { activo: false, texto:'', color:'error' },
        url   : this.$http.options.root + 'fotos-epic-grass/' ,
        photo : 'nophoto.png'
      }
    },

    watch:{
      EstadoCarrito () {
        this.$store.dispatch('abrirCarrito',this.EstadoCarrito)
      },
    },

    computed:{
      ...mapGetters('login',['getusuarioFetish']),
      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -50
      },
    },

    created(){
      this.init();
    },

    methods:{
      ...mapActions('Carrito',['carritoadd','CerrarCarrito']),

      init(){
        this.$http.get('obtener.articulos.random').then( response =>{
          this.productos = response.body
        })
      },

      agregar_a_carrito(item){
        const payload = new Object({
          id           : item.id,
          codigo       : item.codigo,
          nomart       : item.nomart, 
          precio1      : item.precio1,
          precio2      : item.precio2,
          pjedesc      : item.pjedesc,
          descrip      : item.descrip,
          descripLarga : item.descripLarg,
          largo        : item.largo,
          ancho        : item.ancho,
          alto         : item.alto,
          peso         : item.peso,
          foto         : item.foto,
          envoltorio   : item.envoltorio,
          cantidad     : 1
        })

        this.carritoadd(payload).then( response =>{
          this.ver_carrito();
        })
      },
      aniadir_lista_deseos(item){
        const payload = new Object({
          id_cliente: this.getusuarioFetish.idcliente,
          id_producto: item.id,

        })
        this.aniadir_producto_lista_deseos(payload).then(response =>{
          this.alerta = { activo:true, texto: response, color:'blue'}
        }).catch( error =>{
          this.alerta = { activo:true, texto: error, color:'error'}
        })
      },

      ver (item){
        this.$router.push({ name: 'detalle.del.producto',params:{id:item.id}})
      }
    } 
    
  }
</script>

<style>
  .lateral .v-btn--example {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
    padding: 0px !important;
    min-width: 50px !important;
  }

  .lateral .v-btn--descuento{
    bottom: 0;
    position: absolute;
    margin: 0 0 0px 0px;
    min-width: 50px !important;
    z-index: 1;
  }

</style>